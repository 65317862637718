import React from "react";
import { Helmet } from "react-helmet"
import { useStaticQuery, Link, graphql } from "gatsby";
import Logo from '../components/logo.svg';

import layoutStyles from "../styles/layout.module.css";
import Footer from './footer';

const Linker = props => (
	<Link className={props.cls} to={props.to} activeClassName="active">{props.children}</Link>
);

export default function Layout({ children, title, pic }) {
	const data = useStaticQuery(graphql`
			query {
				site {
					siteMetadata {
						env
						author
						title
						description
					}
				}
			}`
	);

	const img = 'https://firebasestorage.googleapis.com/v0/b/debjyotiacharjee-14406.appspot.com/o/Debjyoti-Logo.jpg?alt=media';

	Layout.defaultProps = {
		title: 'Debjyoti Acharjee',
		pic: img
	};

	if (!pic) pic = img;

	return (
		<div className={`container ${layoutStyles.pageDefault}`}>
			<Helmet>
				<title>{title}</title>
				<html lang="en" />
				<meta name="author" content={data.site.siteMetadata.author} />
				<link href="https://fonts.googleapis.com/css2?family=Limelight&family=Roboto:wght@100;300;400&display=swap" rel="stylesheet" />
				<link href="https://use.fontawesome.com/releases/v5.14.0/css/all.css" rel="stylesheet" />
				<link href="https://use.fontawesome.com/releases/v5.14.0/css/v4-shims.css" rel="stylesheet" />
				<meta property="og:title" content={title} />
				<meta property="og:type" content="article" />
				<meta property="og:image" content={pic} />
				<meta property="og:image:secure_url" content={pic} />
				{/* <meta property="og:image:type" content="image/jpeg" />
				<meta property="og:image:width" content="400" />
				<meta property="og:image:height" content="400" />
				<meta property="og:image:alt" content="Debjyoti Acharjee" /> */}
			</Helmet>
			<div className="d-flex flex-column flex-md-row align-items-center mb-3">
				<Linker cls="my-0 mr-md-auto font-weight-normal logo" to="/">
					<img src={Logo} alt={data.site.siteMetadata.title} />
					{data.site.siteMetadata.env === 'DEV' ? <span className="env-flag">[DEV]</span> : ''}
				</Linker>
				<nav className="my-2 my-md-0 mr-md-3">
					{/* <Linker cls="p-2 text-dark page-nav" to="/profile">Profile</Linker> */}
					{/* <Linker cls="p-2 text-dark page-nav" to="/blogs">Blogs</Linker> */}
					{/* <Linker cls="p-2 text-dark page-nav" to="/interests">Interests</Linker> */}
					{/* <Linker cls="p-2 text-dark page-nav" to="/memories">Memories</Linker> */}
				</nav>
			</div>
			{children}
			<Footer></Footer>
		</div>
	);
}